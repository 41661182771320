const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  JOBSEEKER: '/jobseeker',
  EMPLOYER: '/employer',
  ONBOARDING: '/onboarding'
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',

  onboarding: {
    jobseeker: {
      userAccount: `${ROOTS.ONBOARDING}/user-account`,
      experience: `${ROOTS.ONBOARDING}/experience`,
      locationPref: `${ROOTS.ONBOARDING}/location-preference`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    // common paths
    common: {
      user: {
        edit: `${ROOTS.DASHBOARD}/user/edit`
      },
      notications: {
        view: `${ROOTS.DASHBOARD}/notifications/view-all`
      }
    },
    // jobseekers
    jobseekers: {
      profile: `${ROOTS.JOBSEEKER}/profile`,
      location: {
        // new: `${ROOTS.DASHBOARD}/location/new`,
        edit: `${ROOTS.JOBSEEKER}/location/edit`,
      },
      employment: {
        list: `${ROOTS.JOBSEEKER}/employment/list`,
        verify: (id) => `${ROOTS.JOBSEEKER}/employment/${id}/verify`
      },
      pp:`${ROOTS.JOBSEEKER}/privacy-policy`,
      tou:`${ROOTS.JOBSEEKER}/terms-of-use`,
      contactRequest: `${ROOTS.JOBSEEKER}/contact-request/list`,
      experience: {
        new: `${ROOTS.JOBSEEKER}/experience/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/experience/${id}/edit`,
      },
      education: {
        new: `${ROOTS.JOBSEEKER}/education/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/education/${id}/edit`,
      },
      certification: {
        new: `${ROOTS.JOBSEEKER}/certification/new`,
        edit: (id) => `${ROOTS.JOBSEEKER}/certification/${id}/edit`,
      },
      jobs: {
        root: `${ROOTS.JOBSEEKER}/jobs`,
        root_myjob: `${ROOTS.JOBSEEKER}/myjobs`,
        details: (id) => `${ROOTS.JOBSEEKER}/jobs/${id}`,
        saved: `${ROOTS.JOBSEEKER}/myjobs/saved`,
        applied: `${ROOTS.JOBSEEKER}/myjobs/applied`,
        exclusive: `${ROOTS.JOBSEEKER}/jobs/exclusive`,
        non_exclusive: `${ROOTS.JOBSEEKER}/jobs/non-exclusive`
      }
    },
  },
};
