import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthenticatedTemplate } from '@azure/msal-react';

// ----------------------------------------------------------------------


// Doc
const PrivacyPolicyPage = lazy(() => import('src/pages/dashboard/jobseekers/privacy-policy/pp'));
const TermsOfUsePage = lazy(() => import('src/pages/dashboard/jobseekers/terms-of-use/tou'));
// JOBSEEKERS
const JobseekerProfilePage = lazy(() => import('src/pages/dashboard/jobseekers/profile'));
// Location Preference
const LocationPreferenceEditPage = lazy(() => import('src/pages/dashboard/jobseekers/profile/location-preference/edit'));
// Experience
const ExperienceCreatePage = lazy(() => import('src/pages/dashboard/jobseekers/profile/experience/new'));
const ExperienceEditPage = lazy(() => import('src/pages/dashboard/jobseekers/profile/experience/edit'));
// Education
const EducationCreatePage = lazy(() => import('src/pages/dashboard/jobseekers/profile/education/new'));
const EducationEditPage = lazy(() => import('src/pages/dashboard/jobseekers/profile/education/edit'));
// Certification
const CertificationCreatePage = lazy(() => import('src/pages/dashboard/jobseekers/profile/certification/new'));
const CertificationEditPage = lazy(() => import('src/pages/dashboard/jobseekers/profile/certification/edit'));
// Employemnt
const EmploymentListPage = lazy(() => import('src/pages/dashboard/jobseekers/employment/list'));
const VerifyEmploymentPage = lazy(() => import('src/pages/dashboard/jobseekers/employment/verification'));
// ContactRequest
const ContactRequestPage = lazy(() => import('src/pages/dashboard/jobseekers/conact-request'));
// jobs
// Exclusive jobs
const ExclusiveJobListPage = lazy(() => import('src/pages/dashboard/jobseekers/jobs/exclusive/list'))
const ExclusiveJobDetailPage = lazy(() => import('src/pages/dashboard/jobseekers/jobs/exclusive/details'))
const SavedJobPage = lazy(() => import('src/pages/dashboard/jobseekers/jobs/myjobs/saved'))
const AppliedJobPage = lazy(() => import('src/pages/dashboard/jobseekers/jobs/myjobs/applied'))
// Non-Exclusive jobs
const NonExclusiveJobListPage = lazy(() => import('src/pages/dashboard/jobseekers/jobs/non-exclusive/list'))

// ----------------------------------------------------------------------

export const jobseekersRoutes = [
    {
        path: 'jobseeker',
        element: (
            <AuthGuard>
                <AuthenticatedTemplate>
                    <RoleBasedGuard hasContent roles={["LAID_OFF_USER"]} sx={{ py: 10 }}>
                        <DashboardLayout>
                            <OnboardingGuard roles="LAID_OFF_USER">
                                <Suspense fallback={<LoadingScreen />}>
                                    <Outlet />
                                </Suspense>
                            </OnboardingGuard>
                        </DashboardLayout>
                    </RoleBasedGuard>
                </AuthenticatedTemplate>
            </AuthGuard>
        ),
        children: [
            // { element: <IndexPage />, index: true },
            { path: 'profile', element: <JobseekerProfilePage />, index: true },
            { path: 'privacy-policy', element: <PrivacyPolicyPage />, index: true },
            { path: 'terms-of-use', element: <TermsOfUsePage />, index: true },

            {
                path: 'location',
                children: [
                    { element: <LocationPreferenceEditPage />, index: true },
                    // { path: 'new', element: <LocationPreferenceEditPage /> },
                    { path: 'edit', element: <LocationPreferenceEditPage /> },
                ],
            },
            {
                path: 'contact-request',
                children: [
                    { element: <ContactRequestPage />, index: true },
                    { path: 'list', element: <ContactRequestPage /> },
                ]
            },

            {
                path: 'employment',
                children: [
                    { element: <EmploymentListPage />, index: true },
                    { path: 'list', element: <EmploymentListPage /> },
                    { path: ':id/verify', element: <VerifyEmploymentPage /> },
                ],
            },
            {
                path: 'experience',
                children: [
                    { element: <JobseekerProfilePage />, index: true },
                    { path: 'new', element: <ExperienceCreatePage /> },
                    { path: ':id/edit', element: <ExperienceEditPage /> },
                ],
            },
            {
                path: 'education',
                children: [
                    { element: <JobseekerProfilePage />, index: true },
                    { path: 'new', element: <EducationCreatePage /> },
                    { path: ':id/edit', element: <EducationEditPage /> },
                ],
            },
            {
                path: 'certification',
                children: [
                    { element: <JobseekerProfilePage />, index: true },
                    { path: 'new', element: <CertificationCreatePage /> },
                    { path: ':id/edit', element: <CertificationEditPage /> },
                ],
            },

            {
                path: 'myjobs',
                children: [
                    { element: <SavedJobPage />, index: true },
                    { path: 'saved', element: <SavedJobPage /> },
                    { path: 'applied', element: <AppliedJobPage /> },
                ],
            },
            {
                path: 'jobs',
                children: [
                    { element: <ExclusiveJobListPage />, index: true },
                    { path: 'exclusive', element: <ExclusiveJobListPage /> },
                    { path: ':id', element: <ExclusiveJobDetailPage /> },
                ]
            },
            {
                path: 'jobs',
                children: [
                    { element: <NonExclusiveJobListPage />, index: true },
                    { path: 'non-exclusive', element: <NonExclusiveJobListPage /> },
                ]
            },

        ],
    },
];
